import { ActionType } from "typesafe-actions";

import * as Actions from "./goods-receiving-note.actions";

export type IGoodsReceivingNoteActions = ActionType<typeof Actions>;

export * from "./goods-receiving-note.actions";
export * from "./goods-receiving-note.reducer";
export * from "./goods-receiving-note.state";
export * from "./goods-receiving-note.types";
