import { ActionType } from "typesafe-actions";

import * as Actions from "./customerDispatchAddressActions";

export type CustomerDispatchAddressActions = ActionType<typeof Actions>;

export * from "./customDispatchAddress.types";
export * from "./customerDispatchAddressActions";
export * from "./customerDispatchAddressReducer";
export * from "./defaultState";
