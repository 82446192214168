import { ActionType } from "typesafe-actions";

import * as Actions from "./approvals.actions";

export type ApprovalsActions = ActionType<typeof Actions>;

export * from "./approvals.actions";
export * from "./approvals.reducer";
export * from "./approvals.state";
export * from "./approvals.types";
