import { ActionType } from "typesafe-actions";

import * as Actions from "./analyticsActions";

export type AnalyticsAcions = ActionType<typeof Actions>;

export * from "./analytics.types";
export * from "./analyticsActions";
export * from "./analyticsReducer";
export * from "./defaultState";
