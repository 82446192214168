import { ActionType } from "typesafe-actions";

import * as Actions from "./customerBranchActions";

export type CustomerBranchActions = ActionType<typeof Actions>;

export * from "./customBranch.types";
export * from "./customerBranchActions";
export * from "./customerBranchReducer";
export * from "./defaultState";
