import { ActionType } from "typesafe-actions";

import * as Actions from "./quotation.actions";

export type IQuotationActions = ActionType<typeof Actions>;

export * from "./quotation.actions";
export * from "./quotation.reducer";
export * from "./quotation.state";
export * from "./quotation.types";
