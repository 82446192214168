import { IApprovalQueryParams } from "./approvals.types";

export const getApprovalSearchQuery = (
  queryParams: IApprovalQueryParams,
): string => {
  const {
    table_name,
    page,
    rowsPerPage,
    status,
    value,
    columns,
    fromDate,
    toDate,
  } = queryParams;
  let query = `?table_name=${table_name}`;
  if (status) {
    query += `status=${status}&`;
  }
  if (columns.length > 0) {
    query += `columns=${columns}&`;
  }
  if (value) {
    query += `value=${value}&`;
  }
  if (fromDate) {
    query += `from_date=${fromDate}&`;
  }
  if (toDate) {
    query += `to_date=${toDate}&`;
  }
  query += `pageNo=${page}&itemPerPage=${rowsPerPage}`;
  return query;
};
