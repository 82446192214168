import { ActionType } from "typesafe-actions";

import * as Actions from "./projects.actions";

export type IQuotationActions = ActionType<typeof Actions>;

export * from "./projects.actions";
export * from "./projects.reducer";
export * from "./projects.state";
export * from "./projects.types";
