import { ActionType } from "typesafe-actions";

import * as Actions from "./sale-order.actions";

export type ISaleOrderActions = ActionType<typeof Actions>;

export * from "./sale-order.actions";
export * from "./sale-order.reducer";
export * from "./sale-order.state";
export * from "./sale-order.types";
