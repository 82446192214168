import { ActionType } from "typesafe-actions";

import * as Actions from "./customerVendorAttachmentActions";

export type CustomerVendorAttachmentActions = ActionType<typeof Actions>;

export * from "./customerVendorAttachment.types";
export * from "./customerVendorAttachmentActions";
export * from "./customerVendorAttachmentReducer";
export * from "./defaultState";
